import { useState, useContext } from "react";
import { Col, Row, Collapse } from "react-bootstrap";
import PriceFormatter from "../../helpers/PriceFormater";
import { BsChevronDown } from "react-icons/bs";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";
import {
  totalVehiclePrice,
  vehiclePrice,
  totalAccessoryPrice,
  taxeTps,
  taxeTvq,
  vehicleFinalTotalPrice,
  getSummaryTotalCredit,
  getSummaryTotalIncentive,
} from "../../helpers/PaymentCalculatorHelper";

export default function SummaryInvoice() {
  const [openVehiclePrice, setOpenVehiclePrice] = useState(false);
  const [openOptionPrice, setOpenOptionPrice] = useState(false);
  const [openCreditPrice, setOpenCreditPrice] = useState(false);
  const [configurationState] = useContext(ConfigurationStateContext);

  let tps = taxeTps(configurationState);
  let tvq = taxeTvq(configurationState);

  return (
    <div className="cartInvoice">
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">Garniture</div>
        <div className="cartInvoice__option">
          {configurationState?.modele?.toUpperCase()}{" "}
          {configurationState?.trim?.toUpperCase()}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={configurationState?.msrp} />
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">Groupe motopropulseur</div>
        <div className="cartInvoice__option">
          {configurationState?.engine.toUpperCase()}
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">Couleur</div>
        <div className="cartInvoice__option">
          {configurationState?.exteriorDescription}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={configurationState?.exteriorOptionPrice} />
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">Jantes</div>
        <div className="cartInvoice__option">
          {configurationState?.wheelOption[1]}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={configurationState?.wheelOptionPrice} />
        </div>
      </div>
      <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">Accessoires</div>
        <div className="cartInvoice__option">{}</div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={configurationState?.accessoryCumulatedPrice} />
        </div>
      </div>
      {/* <div className="cartInvoice__row">
        <div className="cartInvoice__title h4">Intérieur</div>
        <div className="cartInvoice__option">
          {configurationState?.interiorOption}
        </div>
        <div className="cartInvoice__price ">
          <PriceFormatter price={0} />
        </div>
      </div> */}
      <div className="cartInvoice__row">
        <div className="cartInvoice__title">Prix</div>
        <div className="cartInvoice__option">Prix total</div>
        <div className="cartInvoice__price">
          {<PriceFormatter price={totalVehiclePrice(configurationState)} />}
        </div>
      </div>
      <div className="">
        <div className="h5 mb-3">Prix récapitulatif</div>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => setOpenVehiclePrice(!openVehiclePrice)}
          >
            Prix du véhicule{" "}
            <BsChevronDown
              className={`small invoiceRow__chevron ${
                openVehiclePrice ? "invoiceRow__chevron--flipped" : ""
              }`}
            />
          </div>
          <div className="price_fs">
            <PriceFormatter price={vehiclePrice(configurationState)} />
          </div>
        </div>
        <Collapse in={openVehiclePrice}>
          <div>
            <div className="pt-1">
              <div className="d-flex justify-content-between">
                <div className="ps-2">MSRP</div>
                <div>
                  <PriceFormatter price={configurationState?.msrp} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">Frais de transport et de préparation</div>
                <div>
                  <PriceFormatter price={configurationState?.prepFee} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">Taxe sur le climatiseur</div>
                <div>
                  <PriceFormatter price={configurationState?.acFee} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">Droits sur les pneus neufs</div>
                <div>
                  <PriceFormatter price={configurationState?.tiresFee} />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => setOpenOptionPrice(!openOptionPrice)}
          >
            Prix des options{" "}
            <BsChevronDown
              className={`small invoiceRow__chevron ${
                openOptionPrice ? "invoiceRow__chevron--flipped" : ""
              }`}
            />
          </div>
          <div className="price_fs">
            <PriceFormatter price={totalAccessoryPrice(configurationState)} />
          </div>
        </div>
        <Collapse in={openOptionPrice}>
          <div>
            <div className="pt-1">
              <div className="d-flex justify-content-between">
                <div className="ps-2">Exterior Option</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.exteriorOptionPrice}
                  />
                </div>
              </div>
              {/* <div className="d-flex justify-content-between">
                <div className="ps-2">Interior Option</div>
                <div>
                  <PriceFormatter price={0} />
                </div>
              </div> */}
              <div className="d-flex justify-content-between">
                <div className="ps-2">Wheel Option</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.wheelOptionPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">Accessory Option</div>
                <div>
                  <PriceFormatter
                    price={configurationState?.accessoryCumulatedPrice}
                  />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div
            className=""
            onClick={() => setOpenCreditPrice(!openCreditPrice)}
          >
            Credit{" "}
            <BsChevronDown
              className={`small invoiceRow__chevron ${
                openCreditPrice ? "invoiceRow__chevron--flipped" : ""
              }`}
            />
          </div>
          <div className="price_fs">
            <PriceFormatter price={getSummaryTotalCredit(configurationState)} />
          </div>
        </div>
        <Collapse in={openCreditPrice}>
          <div>
            <div className="pt-1">
              <div className="d-flex justify-content-between">
                <div className="ps-2">Trade-In</div>
                <div>
                  <PriceFormatter price={configurationState?.tradeIn} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">Down Payment</div>
                <div>
                  <PriceFormatter price={configurationState?.downPayment} />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ps-2">Primes</div>
                <div>
                  <PriceFormatter
                    price={getSummaryTotalIncentive(configurationState)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div className="">TPS</div>
          <div className="price_fs">
            <PriceFormatter price={taxeTps(configurationState)} />
          </div>
        </div>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div className="">TVQ</div>
          <div className="price_fs">
            <PriceFormatter price={taxeTvq(configurationState)} />
          </div>
        </div>
        <Row
          xs="auto"
          className="bg-light py-2 align-items-center justify-content-between"
        >
          <Col>Total</Col>
          <Col className="price_fs fw-bold">
            <PriceFormatter
              price={vehicleFinalTotalPrice(configurationState, tps, tvq)}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
