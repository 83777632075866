const initialState = {
  dealerInfo: [],
  make: [],
  allModel: [],
  modele: [],
  vehicles: [],
  vehicle: [],
  vehicleLender: [],
  selectedColor: [],
  inventoryVehicle: [],
  paymentConfigLease: [],
  paymentConfigLoan: [],
  paymentConfigCash: [],
  configurationState: [],
  existingConfigurationState: [],
  error: "",
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "FETCH_DEALER_INFO":
      return {
        ...state,
        dealerInfo: action.payload,
      };
    case "FETCH_MAKE":
      return {
        ...state,
        make: action.payload,
      };
    case "FETCH_ALL_MODEL":
      return {
        ...state,
        allModel: action.payload,
      };
    case "FETCH_MODEL":
      return {
        ...state,
        modele: action.payload,
      };
    case "FETCH_TRIM":
      return {
        ...state,
        vehicles: action.payload,
      };
    case "FETCH_VEHICLE":
      return {
        ...state,
        vehicle: action.payload,
      };
    case "FETCH_VEHICLE_LENDER":
      return {
        ...state,
        vehicleLender: action.payload,
      };
    case "FETCH_REVERSE_SLUG":
      return {
        ...state,
        slug: action.payload,
      };
    case "FETCH_SELECTED_COLOR":
      return {
        ...state,
        selectedColor: action.payload,
      };
    case "FETCH_INVENTORY_VEHICLE":
      return {
        ...state,
        inventoryVehicle: action.payload,
      };
    case "FETCH_EXISTING_BUILD":
      return {
        ...state,
        existingConfigurationState: action.payload,
      };
    case "CREATE_LEAD":
      return {
        ...state,
        configurationState: action.payload,
      };
    case "CREATE_BUILD_URL":
      return {
        ...state,
        configurationState: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
