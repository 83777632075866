import printValue from 'yup/es/util/printValue';
import {
    MixedLocale,
    StringLocale,
    NumberLocale,
    BooleanLocale,
    DateLocale,
    ObjectLocale,
    ArrayLocale,
    LocaleObject,
} from 'yup/es/locale';

/* eslint-disable no-template-curly-in-string, prefer-template */
const mixed: Required<MixedLocale> = {
    default: 'Le champ ${path} est invalide',
    required: 'Le champ ${path} est obligatoire',
    defined: 'Le champ ${path} est obligatoire',
    oneOf: '${path} doit correspondre à une des valeurs suivantes: ${values}',
    notOneOf:
        '${path} ne peut correspondre à aucune des valeurs suivantes: ${values}',
    notType: ({ path, type, value, originalValue }) => {
        const castMsg =
            originalValue != null && originalValue !== value
                ? ` (convertie à partir de la valeur \`${printValue(
                      originalValue,
                      true
                  )}\`).`
                : '.';

        return type !== 'mixed'
            ? `${path} doit être de type \`${type}\`, ` +
                  `mais sa valeur est: \`${printValue(value, true)}\`` +
                  castMsg
            : `${path} doit correspondre au type configuré. ` +
                  `La valeur validée est: \`${printValue(value, true)}\`` +
                  castMsg;
    },
};

const string: Required<StringLocale> = {
    length: '${path} doit comporter exactement ${length} caractères',
    min: '${path} doit comporter au moins ${min} caractères',
    max: '${path} doit comporter un maximum de ${max} caractères',
    matches: '${path} doit correspondre à l\'expression régulière: "${regex}"',
    email: '${path} doit être un courriel valide',
    url: '${path} doit être un URL valide',
    uuid: '${path} doit être un UUID valide',
    trim: "${path} ne doit pas comporter d'espaces au début ou à la fin",
    lowercase: '${path} doit être en minuscules',
    uppercase: '${path} doit être en majuscules',
};

export const number: Required<NumberLocale> = {
    min: '${path} doit être plus grand ou égal à ${min}',
    max: '${path} doit être plus petit ou égal à ${max}',
    lessThan: '${path} doit être plus petit que ${less}',
    moreThan: '${path} doit être plus grand que ${more}',
    positive: '${path} doit être un nombre positif',
    negative: '${path} doit être un nombre négatif',
    integer: '${path} doit être un nombre entier',
};

const date: Required<DateLocale> = {
    min: '${path} doit être après ${min}',
    max: '${path} doit être avant ${max}',
};

const boolean: BooleanLocale = {
    isValue: ({ value, path }) =>
        `$${path} doit être ${value ? 'vrai' : 'faux'}`,
};

const object: Required<ObjectLocale> = {
    noUnknown: '${path} doit comporter les valeurs suivantes: ${unknown}',
};

const array: Required<ArrayLocale> = {
    min: '${path} doit avoir au moins ${min} valeurs',
    max: '${path} doit avoir un maximum de ${max} valeurs',
    length: '${path} doit avoir exactement ${length} valeurs',
};

const locale: LocaleObject = {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
};

export default locale;