import { useContext, PropsWithChildren } from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HiOutlineCheck } from "react-icons/hi";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";

export interface ExteriorColor {
  id?: string | number;
  headerName?: any;
  msrp?: string | number;
  rgbValue?: string;
  descriptions?: any;
  color?: string;
}

export interface ExteriorColorOptionsProps {
  title: string;
  exteriorColors: ExteriorColor[];
  eventKey: string;
}

export default function ExteriorColorOptions({
  title,
  exteriorColors,
  eventKey,
}: PropsWithChildren<ExteriorColorOptionsProps>) {
  const colorOptions = exteriorColors?.filter(
    (e) => e?.headerName === "PEINTURE PRIMAIRE"
  );

  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );

  function selectColor(hex: any, description: any, price: any) {
    setConfigurationState({
      ...configurationState,
      exteriorOption: hex,
      exteriorDescription: description,
      exteriorOptionPrice: price,
    });
  }
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        <Row className="g-2" xs="5">
          {colorOptions?.map((color) => (
            <Col key={color.rgbValue}>
              <input
                className="d-none"
                type="radio"
                name="extColor"
                id={`extColor-${color.rgbValue}`}
                value={color.msrp}
                onChange={() =>
                  selectColor(
                    color.rgbValue,
                    color.descriptions.description,
                    color.msrp
                  )
                }
              />
              <label
                className="selector color-display"
                htmlFor={`extColor-${color.rgbValue}`}
                style={{ backgroundColor: `#${color?.rgbValue}` }}
                role="button"
              >
                {configurationState.exteriorOption === color?.rgbValue && (
                  <HiOutlineCheck className="check-circle"></HiOutlineCheck>
                )}
              </label>
            </Col>
          ))}
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
