export const paymentMonthlyLoan = (
  configurationState: any,
  duration: any,
  rate: any
) => {
  let total =
    configurationState.msrp +
    configurationState.acFee +
    configurationState.tiresFee +
    configurationState.prepFee -
    configurationState.cumulatedCashIncentive -
    configurationState.cumulatedIncentiveTotal +
    250 +
    50.58;
  //missing RDPRM
  let realRate = rate / 12 / 100;

  let interestPaid =
    (total * (realRate * Math.pow(1 + realRate, duration))) /
    (Math.pow(1 + realRate, duration) - 1);
  return interestPaid;
};

export const paymentByWeeklyLoan = (
  configurationState: any,
  duration: any,
  rate: any
) => {
  let total =
    configurationState.msrp +
    configurationState.acFee +
    configurationState.tiresFee +
    configurationState.prepFee -
    configurationState.cumulatedCashIncentive -
    configurationState.cumulatedIncentiveTotal +
    250 +
    50.58;

  let realRate = rate / 12 / 100;

  let interestPaid =
    (total * (realRate * Math.pow(1 + realRate, duration))) /
    (Math.pow(1 + realRate, duration) - 1);
  return (interestPaid * 12) / 26;
};

export const paymentMonthlyLease = (
  configurationState: any,
  duration: any,
  rate: any
) => {
  let realRate = rate / 24 / 100;

  let total =
    configurationState.msrp +
    configurationState.prepFee +
    configurationState.acFee +
    configurationState.tiresFee -
    configurationState.cumulatedCashIncentive -
    configurationState.cumulatedIncentiveTotal +
    350 +
    95.62 -
    250;
  let totalForRedisual =
    configurationState.msrp +
    configurationState.prepFee +
    configurationState.acFee +
    configurationState.tiresFee;

  let residual = (totalForRedisual * configurationState.residual) / 100;
  // console.log(residual);

  let financing = total - residual;
  let moneyFactor = realRate;

  let depreciation = financing / duration;

  let interestCharge = (total + residual) * moneyFactor;
  let something = depreciation + interestCharge;

  //let interestPaid = financing * (realRate * Math.pow((1+realRate), duration)) / (Math.pow((1+realRate), duration) - 1);

  return something;
};

export const paymentByWeeklyLease = (
  configurationState: any,
  duration: any,
  rate: any
) => {
  let realRate = rate / 24 / 100;

  let total =
    configurationState.msrp +
    configurationState.prepFee +
    configurationState.acFee +
    configurationState.tiresFee -
    configurationState.cumulatedCashIncentive -
    configurationState.cumulatedIncentiveTotal +
    350 +
    95.62 -
    250;
  let totalForRedisual =
    configurationState.msrp +
    configurationState.prepFee +
    configurationState.acFee +
    configurationState.tiresFee;

  let residual = (totalForRedisual * configurationState.residual) / 100;

  let financing = total - residual;
  let moneyFactor = realRate;

  let depreciation = financing / duration;

  let interestCharge = (total + residual) * moneyFactor;
  let something = depreciation + interestCharge;

  //let interestPaid = financing * (realRate * Math.pow((1+realRate), duration)) / (Math.pow((1+realRate), duration) - 1);

  return (something * 12) / 26;
};

export const totalVehiclePrice = (configurationState: any) => {
  let total =
    configurationState.msrp +
    configurationState.acFee +
    configurationState.tiresFee +
    configurationState.prepFee +
    configurationState.accessoryCumulatedPrice +
    configurationState.interiorAccessoryCumulatedPrice +
    configurationState.groupAccessoryCumulatedPrice +
    configurationState.exteriorOptionPrice +
    configurationState.wheelOptionPrice -
    configurationState.cumulatedCashIncentive -
    configurationState.cumulatedIncentiveTotal;
  return total;
};

export const vehiclePrice = (configurationState: any) => {
  let total =
    configurationState.msrp +
    configurationState.acFee +
    configurationState.prepFee +
    configurationState.tiresFee;

  return total;
};

export const totalAccessoryPrice = (configurationState: any) => {
  let total =
    configurationState.accessoryCumulatedPrice +
    configurationState.interiorAccessoryCumulatedPrice +
    configurationState.groupAccessoryCumulatedPrice +
    configurationState.exteriorOptionPrice +
    configurationState.wheelOptionPrice;

  return total;
};

export const taxeTps = (configurationState: any) => {
  let total =
    (configurationState.msrp +
      configurationState.acFee +
      configurationState.tiresFee +
      configurationState.prepFee +
      configurationState.accessoryCumulatedPrice +
      configurationState.interiorAccessoryCumulatedPrice +
      configurationState.groupAccessoryCumulatedPrice +
      configurationState.exteriorOptionPrice +
      configurationState.wheelOptionPrice -
      configurationState.cumulatedCashIncentive -
      configurationState.cumulatedIncentiveTotal) *
    0.05;

  return total;
};

export const taxeTvq = (configurationState: any) => {
  let total =
    (configurationState.msrp +
      configurationState.acFee +
      configurationState.tiresFee +
      configurationState.prepFee +
      configurationState.accessoryCumulatedPrice +
      configurationState.interiorAccessoryCumulatedPrice +
      configurationState.groupAccessoryCumulatedPrice +
      configurationState.exteriorOptionPrice +
      configurationState.wheelOptionPrice -
      configurationState.cumulatedCashIncentive -
      configurationState.cumulatedIncentiveTotal) *
    0.09975;

  return total;
};

export const vehicleFinalTotalPrice = (
  configurationState: any,
  tps: any,
  tvq: any
) => {
  let total = 0;

  if (configurationState.paymentMethod !== "cash") {
    total =
      configurationState.msrp +
      configurationState.acFee +
      configurationState.tiresFee +
      configurationState.prepFee +
      configurationState.accessoryCumulatedPrice +
      configurationState.interiorAccessoryCumulatedPrice +
      configurationState.groupAccessoryCumulatedPrice +
      configurationState.exteriorOptionPrice +
      configurationState.wheelOptionPrice -
      configurationState.cumulatedCashIncentive -
      configurationState.cumulatedIncentiveTotal +
      tps +
      tvq -
      configurationState.downPayment -
      configurationState.tradeIn;
  } else {
    total =
      configurationState.msrp +
      configurationState.acFee +
      configurationState.tiresFee +
      configurationState.prepFee +
      configurationState.accessoryCumulatedPrice +
      configurationState.interiorAccessoryCumulatedPrice +
      configurationState.groupAccessoryCumulatedPrice +
      configurationState.exteriorOptionPrice +
      configurationState.wheelOptionPrice +
      configurationState.cumulatedCashIncentive -
      configurationState.cumulatedIncentiveTotal +
      tps +
      tvq -
      configurationState.tradeIn;
  }

  return total;
};

export const getSummaryTotalCredit = (configurationState: any) => {
  let total =
    configurationState.cashEvent +
    configurationState.cumulatedIncentiveTotal +
    Number(configurationState.tradeIn) +
    Number(configurationState.downPayment);
  return total;
};

export const getSummaryTotalIncentive = (configurationState: any) => {
  let total =
    configurationState.cashEvent + configurationState.cumulatedIncentiveTotal;
  return total;
};

export const getEstimatedLeaseMonthlyPayment = (configurationState: any) => {
  let realRate = configurationState.leaseRates / 24 / 100;

  let total =
    configurationState.msrp +
    configurationState.prepFee +
    configurationState.acFee +
    configurationState.tiresFee -
    configurationState.cumulatedCashIncentive -
    configurationState.cumulatedIncentiveTotal +
    350 +
    95.62 -
    250;
  let totalForRedisual =
    configurationState.msrp +
    configurationState.prepFee +
    configurationState.acFee +
    configurationState.tiresFee;

  let residual = (totalForRedisual * configurationState.residual) / 100;
  // console.log(residual);

  let financing = total - residual;
  let moneyFactor = realRate;

  let depreciation = financing / configurationState.leaseDuration;

  let interestCharge = (total + residual) * moneyFactor;
  let monthlyPayment = depreciation + interestCharge;

  //let interestPaid = financing * (realRate * Math.pow((1+realRate), duration)) / (Math.pow((1+realRate), duration) - 1);

  return monthlyPayment;
};

export const getEstimatedLoanMonthlyPayment = (configurationState: any) => {
  let total =
    configurationState.msrp +
    configurationState.acFee +
    configurationState.tiresFee +
    configurationState.prepFee -
    configurationState.cumulatedCashIncentive -
    configurationState.cumulatedIncentiveTotal +
    250 +
    50.58;
  //missing RDPRM
  let realRate = configurationState.loanRates / 12 / 100;

  let interestPaid =
    (total *
      (realRate * Math.pow(1 + realRate, configurationState.loanDuration))) /
    (Math.pow(1 + realRate, configurationState.loanDuration) - 1);
  return interestPaid;
};
