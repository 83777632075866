import { PropsWithChildren } from "react";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export interface OptionModalProps {
  data: any;
  showModal: boolean;
  handleCloseModal: any;
}

export default function OptionModal({
  data,
  showModal,
  handleCloseModal,
}: PropsWithChildren<OptionModalProps>) {
  return (
    <Modal
      fullscreen={"md-down"}
      size="lg"
      centered
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{data[1]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg="6" className="mb-3">
            {/* need ratio to prevent img from having 0 height before load */}
            <img className="w-100 ratio-1-1" src={data[2]} alt={data[1]} />
          </Col>
          <Col lg="6">
            <div className="h5 text-primary">{data[0]} $</div>
            <p>{data[3]}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
