import i18n from 'i18next';
import ICU from 'i18next-icu';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

export const languages = ['fr', 'en'] as const;
export const defaultLanguage = languages[0];

export type ValidLanguage = typeof languages[number];

export const language = /^\/en/i.test(window.location.pathname) ? 'en' : 'fr';

i18n.use(ICU)
    .use(initReactI18next)
    .use(
        resourcesToBackend((lng, ns, clb) =>
            import(`../../public/locales/${lng}/${ns}.json`)
                .then(({ default: resources }) => clb(null, resources))
                .catch((err) => clb(err, null))
        )
    )
    .init({
        fallbackLng: languages,
        saveMissing: true,
        returnObjects: true,
        lng: language,
    });

export default i18n;