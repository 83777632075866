import { PropsWithChildren } from "react";

export interface PageTitleProps {
  titleOne?: string;
  titleTwo?: string;
}

export default function PageTitle({
  titleOne,
  titleTwo,
}: PropsWithChildren<PageTitleProps>) {
  return (
    <div className="mb-4">
      <div className="h1">
        {titleOne?.toUpperCase()} {titleTwo?.toUpperCase()}
      </div>
    </div>
  );
}
