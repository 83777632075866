import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./router/AppRoutes";
import { Provider } from "react-redux";
import Compose from "./utils/Compose";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import "bootstrap/dist/css/bootstrap.min.css";
import reducers from "./reducers";
import "./assets/sass/style.scss";
import ConfigurationStateProvider from "./ConfigurationStateProvider";
import { setLocale } from "yup";
import { defaultLanguage, language } from "./i18n";
import locales from "./i18n/yup/locales";

const store = createStore(reducers, compose(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

setLocale(locales[language]);

root.render(
  <React.StrictMode>
    <Compose
      items={[
        [Provider, { store }],
        [
          BrowserRouter,
          {
            basename:
              language === defaultLanguage ? undefined : `/${language}/`,
          },
        ],
      ]}
    >
      <ConfigurationStateProvider>
        <AppRoutes />
      </ConfigurationStateProvider>
    </Compose>
  </React.StrictMode>
);
