import { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  BsChevronRight,
  BsChevronLeft,
  BsArrowLeftShort,
} from "react-icons/bs";
import { Container } from "react-bootstrap";

export interface NavBarProps {
  slug?: any;
  url?: any;
  active?: string;
  step?: any;
}

export default function NavBar({
  slug,
  url,
  active,
  step,
}: PropsWithChildren<NavBarProps>) {
  const { parentUrl, make, model, trim } = useParams();
  const { t } = useTranslation();

  const navLinks = [
    {
      slug: "model",
      name: t("navbar.model"),
      url: make ? `/${parentUrl}/${make}` : null,
    },
    {
      slug: "trim",
      name: t("navbar.version"),
      url: model ? `/${parentUrl}/${make}/${model}` : null,
    },
    {
      slug: "configuration",
      name: t("navbar.configuration"),
      url: trim ? `/${parentUrl}/${make}/${model}/${trim}` : null,
    },
    {
      slug: "summary",
      name: t("navbar.summary"),
      url: trim ? `/${parentUrl}/${make}/${model}/${trim}/summary` : null,
    },
  ];

  const currentPageIndex = navLinks.findIndex((e: any) => e.slug === active);
  const previousPage = navLinks[currentPageIndex - 1];

  return (
    <Container className="mb-5">
      <div className="steps">
        {navLinks.map((e: any, i: any) => (
          <div
            key={i}
            className={`steps__item ${e.slug === active ? "active" : ""}`}
          >
            {/* adds a previous arrow if there is a previous step (mobile only) */}
            {navLinks[i - 1] && (
              <Link
                to={navLinks[i - 1].url || "/"}
                className={`steps__arrow steps__arrow--back`}
              >
                <BsChevronLeft />
              </Link>
            )}
            {/* set active styling if the link is the same as the prop.active */}
            <Link
              to={e.url || "/"}
              className={`steps__link ${e.slug === active ? "active" : ""} ${
                !e.url ? "is-disabled" : ""
              }`}
            >
              {e.name}
            </Link>
            {/* adds a next arrow if there is a previous step. (On desktop this is only for the esthetic) */}
            {navLinks[i + 1] && (
              // link is disabled if we dont have the data (ex: cannot go in summary if we havent select a trim)
              <Link
                to={navLinks[i + 1].url || "/"}
                className={`steps__arrow steps__arrow--next ${
                  !navLinks[i + 1].url ? "is-disabled" : ""
                }`}
              >
                <BsChevronRight />
              </Link>
            )}
          </div>
        ))}
      </div>
      {previousPage?.url && (
        <Link
          className="mt-3 d-inline-flex align-items-center text-decoration-none text-reset"
          to={previousPage.url}
        >
          <BsArrowLeftShort />
          Back
        </Link>
      )}
    </Container>
  );
}
