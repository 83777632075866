import { PropsWithChildren } from "react";
/**
 * Example of use: <Switch toggle={() => setSelected(selected => !selected)} selected={selected} />
 * @param {Function} toggle function that toggles the selected state
 * @param {Bool} selected
 */
export interface SwitchProps {
  toggle: any;
  selected: any;
  className: any;
}

export default function Switch({
  toggle,
  selected,
  className,
}: PropsWithChildren<SwitchProps>) {
  return (
    <label
      className={`switch ${selected ? "switch--active" : ""} ${className}`}
    >
      <input type="checkbox" checked={selected} onChange={toggle} />
      <span className="switch__toggle"></span>
    </label>
  );
}
