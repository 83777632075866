import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllModel} from "../actions/index";
import PageTitle from "../components/common/PageTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import VehicleModelCard from "../components/cards/VehicleModelCard";

export default function VehiclesModelPage() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { parentUrl, make } = useParams();
  const lang = i18n.language;
  const models = useSelector((state: any) => state.allModel);

  useEffect(() => {
    dispatch<any>(getAllModel(parentUrl, make, lang));
  }, [dispatch, parentUrl, make, lang]);

  return (
    <Container>
      <PageTitle titleOne={make} />
      {!!models &&
        <Row className="g-3 g-sm-5">
          {models.map((e:any) => (
            <Col md="6" lg="4" key={e.id}>
              <VehicleModelCard
                img={e.exteriorImages[0]?.img_url}
                make={e.make}
                model={e.model}
                modelSlug={e.model_slug}
                year={e.year}
              />
            </Col>
          ))}
        </Row>
      }
    </Container>
  );
}
