import { useEffect, useState, PropsWithChildren } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BsZoomIn } from "react-icons/bs";
import OptionModal from "../modals/OptionModal";

export interface SummaryAccessoryProps {
  accessory: any;
}

export default function SummaryAccessory({
  accessory,
}: PropsWithChildren<SummaryAccessoryProps>) {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (boolean: boolean) => setShowModal(boolean);

  return (
    <>
      <div className="position-relative">
        <img className="w-100" src={accessory[2]} alt={accessory[1]} />
        <div className="triangle">
          <div
            role="button"
            className="triangle__btn"
            onClick={() => handleShowModal(true)}
          >
            <BsZoomIn />
          </div>
        </div>
        <OptionModal
          data={accessory}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </>
  );
}
