import * as api from "../api";
import noImg from "../assets/img/image-placeholder.webp";

export const getDealerInfo =
  (parentUrl?: string, lang?: string) => async (dispatch: any) => {
    try {
      const { data } = await api.fetchDealerInfo(parentUrl, lang);
      dispatch({ type: "FETCH_DEALER_INFO", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getMake = (parentUrl?: string) => async (dispatch: any) => {
  try {
    const { data } = await api.fetchMake(parentUrl);
    dispatch({ type: "FETCH_MAKE", payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getAllModel =
  (parentUrl?: string, make?: string, lang?: string) =>
  async (dispatch: any) => {
    try {
      const { data } = await api.fetchAllModel(parentUrl, make, lang);
      dispatch({ type: "FETCH_ALL_MODEL", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getModel =
  (parentUrl?: string, model?: string) => async (dispatch: any) => {
    try {
      const { data } = await api.fetchModel(parentUrl, model);
      dispatch({ type: "FETCH_MODEL", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getTrim =
  (parentUrl?: string, model?: string, lang?: string) =>
  async (dispatch: any) => {
    try {
      const { data } = await api.fetchTrim(parentUrl, model, lang);
      dispatch({ type: "FETCH_TRIM", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getVehicle =
  (parentUrl?: string, model?: string, trim?: string, lang?: string) =>
  async (dispatch: any) => {
    try {
      const { data } = await api.fetchVehicle(parentUrl, model, trim, lang);
      dispatch({ type: "FETCH_VEHICLE", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getVehicleLender =
  (parentUrl?: string, model?: string, trim?: string, lang?: string) =>
  async (dispatch: any) => {
    try {
      const { data } = await api.fetchVehicleLender(
        parentUrl,
        model,
        trim,
        lang
      );
      dispatch({ type: "FETCH_VEHICLE_LENDER", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getReverseSlug =
  (parentUrl?: string, lang?: string, model?: string) =>
  async (dispatch: any) => {
    try {
      const { data } = await api.fetchReverseSlug(parentUrl, lang, model);
      dispatch({ type: "FETCH_REVERSE_SLUG", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getSelectedColor =
  (parentUrl?: string, model?: string, trim?: string, color?: string) =>
  async (dispatch: any) => {
    try {
      const { data } = await api.fetchSelectedColor(
        parentUrl,
        model,
        trim,
        color
      );
      if (data.length) {
        dispatch({ type: "FETCH_SELECTED_COLOR", payload: data });
      } else {
        dispatch({ type: "FETCH_SELECTED_COLOR", payload: [{img_url: noImg}] });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getInventoryVehicle =
  (parentUrl?: string) => async (dispatch: any) => {
    try {
      const { data } = await api.fetchInventoryVehicle(parentUrl);
      dispatch({ type: "FETCH_INVENTORY_VEHICLE", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const getExistingBuild =
  (parentUrl: any, uniqid: any) => async (dispatch: any) => {
    try {
      const { data } = await api.fetchSummaryBuild(parentUrl, uniqid);
      dispatch({ type: "FETCH_EXISTING_BUILD", payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const createLead =
  (parentUrl: any, configurationState: any, navigate: any) =>
  async (dispatch: any) => {
    try {
      const { data } = await api.createLead(parentUrl, configurationState);
      dispatch({ type: "CREATE_LEAD", payload: data });
      navigate("../confirmation", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

export const createBuildUrl =
  (parentUrl: any, configurationState: any) => async (dispatch: any) => {
    try {
      const { data } = await api.createBuildUrl(parentUrl, configurationState);
      dispatch({ type: "CREATE_BUILD", payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
