import { Link } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import { BsCheck2Circle, BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function ConfirmationPage() {
  const { t } = useTranslation();

  return (
    <main className="formConfirmation">
      <Container className="formConfirmation-content">
        <Card className="formConfirmation-card">
          <Card.Body className="formConfirmation-body">
            <div className="mb-3">
              <BsCheck2Circle className="formConfirmation-check" />
            </div>
            <h1 className="mb-3">{t("formConfirmation.title")}</h1>
            <p className="mb-5 h5">{t("formConfirmation.description")}</p>
            <Row xs={1} sm={2} className="g-2 g-sm-3 justify-content-center">
              <Col>
                <Link className="btn btn-secondary w-100" to="/">
                  <BsArrowLeft className="me-2 align-middle" />
                  <span className="align-middle">
                    {t("formConfirmation.backBtn")}
                  </span>
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </main>
  );
}
