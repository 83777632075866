import { useState, createContext } from "react";

export interface ConfigurationState {
  fullname: string;
  phone: string;
  email: string;
  comment: string;
  creditReport: false;
  vehicleId: string;
  make: string;
  model: string;
  trim: string;
  year: string;
  engine: string;
  seat: string;
  transmission: [];
  fuelEcon: string;
  fuelType: string;
  msrp: Number;
  prepFee: Number;
  exteriorOption: string;
  exteriorDescription: string;
  exteriorOptionPrice: 0;
  wheelOption: [];
  wheelOptionPrice: 0;
  interiorOption: [];
  interiorOptionPrice: 0;
  accessoryOption: [];
  accessoryOptionTotalPrice: [];
  accessoryCumulatedPrice: 0;
  interiorAccessoryOption: [];
  interiorAccessoryOptionTotalPrice: [];
  interiorAccessoryCumulatedPrice: 0;
  groupAccessoryOption: [];
  groupAccessoryOptionTotalPrice: [];
  groupAccessoryCumulatedPrice: 0;
  tiresFee: 15;
  acFee: 100;
  paymentMethod: "loan";
  loanDuration: 60;
  loanRates: Number;
  leaseDuration: 60;
  leaseRates: Number;
  annualKilometer: Number;
  residual: Number;
  tradeIn: 0;
  downPayment: 0;
  incentiveOption: [];
  cashIncentiveOption: [];
  cumulatedCashIncentive: 0;
  cumulatedIncentiveTotal: 0;
  cashEvent: 0;
  exchangeVehicleValue: 0;
  initialDeposit: 0;
  totalFinalPrice: 0;
}

export const ConfigurationStateContext = createContext<any>(null);

const ConfigurationStateProvider = ({ children }: any) => {
  const [configurationState, setConfigurationState] = useState<any>({
    fullname: "",
    phone: "",
    email: "",
    comment: "",
    creditReport: false,
    vehicleId: "",
    make: "",
    model: "",
    trim: "",
    year: "",
    engine: "",
    seat: "",
    transmission: [],
    fuelEcon: "",
    fuelType: "",
    msrp: 0,
    prepFee: 0,
    exteriorOption: "",
    exteriorDescription: "",
    exteriorOptionPrice: 0,
    wheelOption: [],
    wheelOptionPrice: 0,
    interiorOption: [],
    interiorOptionPrice: 0,
    accessoryOption: [],
    accessoryOptionTotalPrice: [],
    accessoryCumulatedPrice: 0,
    interiorAccessoryOption: [],
    interiorAccessoryOptionTotalPrice: [],
    interiorAccessoryCumulatedPrice: 0,
    groupAccessoryOption: [],
    groupAccessoryOptionTotalPrice: [],
    groupAccessoryCumulatedPrice: 0,
    tiresFee: 15,
    acFee: 100,
    paymentMethod: "cash",
    loanDuration: 60,
    loanRates: 0,
    leaseDuration: 60,
    leaseRates: 0,
    annualKilometer: 20000,
    residual: 0,
    tradeIn: 0,
    downPayment: 0,
    incentiveOption: [],
    cashIncentiveOption: [],
    cumulatedCashIncentive: 0,
    cumulatedIncentiveTotal: 0,
    cashEvent: 0,
    exchangeVehicleValue: 0,
    initialDeposit: 0,
    totalFinalPrice: 0,
  });

  return (
    <ConfigurationStateContext.Provider
      value={[configurationState, setConfigurationState]}
    >
      {children}
    </ConfigurationStateContext.Provider>
  );
};

export default ConfigurationStateProvider;
