import { PropsWithChildren } from "react";
import Carousel from "react-bootstrap/Carousel";

type Image = {
  id: string | number;
  img_url?: string;
  title?: string;
  description?: string;
};

export interface VehicleimagesCarrouselProps {
  img?: Image[];
  className?: string;
}

export default function VehicleImagesCarrousel({
  img,
  className,
}: PropsWithChildren<VehicleimagesCarrouselProps>) {
  if (img?.length === 0) return <></>;

  return (
    <Carousel
      indicators={false}
      interval={null}
      className={`carousel carousel--inside ${className}`}
    >
      {img?.map((e) => (
        <Carousel.Item key={e.img_url}>
          <img className="d-block w-100" src={e.img_url} alt={e.title} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
