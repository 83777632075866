import axios from "axios";

export const fetchDealerInfo = (parentUrl?: string, lang?: string) =>
  axios.get(`https://${parentUrl}.ca/api/dealerinfo/${lang}`);

export const fetchMake = (parentUrl?: string) =>
  axios.get(`https://${parentUrl}.ca/api/make`);

export const fetchAllModel = (
  parentUrl?: string,
  make?: string,
  lang?: string
) => axios.get(`https://${parentUrl}.ca/api/model/${make}/${lang}`);

export const fetchModel = (parentUrl?: string, model?: string) =>
  axios.get(`https://${parentUrl}.ca/api/model/${model}`);

export const fetchTrim = (parentUrl?: string, model?: string, lang?: string) =>
  axios.get(`https://${parentUrl}.ca/api/trim/${model}/${lang}`);

export const fetchVehicle = (
  parentUrl?: string,
  model?: string,
  vehicle?: string,
  lang?: string
) =>
  axios.get(`https://${parentUrl}.ca/api/vehicle/${model}/${vehicle}/${lang}`);

export const fetchVehicleLender = (
  parentUrl?: string,
  model?: string,
  vehicle?: string,
  lang?: string
) =>
  axios.get(`https://${parentUrl}.ca/api/lender/${model}/${vehicle}/${lang}`);

export const fetchReverseSlug = (
  parentUrl?: string,
  lang?: string,
  model?: string
) => axios.get(`https://${parentUrl}.ca/api/slug/${lang}/${model}`);

export const fetchSelectedColor = (
  parentUrl?: string,
  model?: string,
  vehicle?: string,
  color?: string
) =>
  axios.get(`https://${parentUrl}.ca/api/color/${model}/${vehicle}/${color}`);

export const fetchInventoryVehicle = (parentUrl?: string) =>
  axios.get(`https://${parentUrl}.ca/api/inventory/new`, {});

export const fetchSummaryBuild = (parentUrl: any, uniqid: any) =>
  axios.get(`https://${parentUrl}.ca/api/configuration/${uniqid}`);

export const createLead = (parentUrl: any, configurationState: any) =>
  axios.post(`https://${parentUrl}.ca/api/sendLead/`, {
    configurationState,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "X-Requested-With, Content-Type, X-Token-Auth,Authorization",
    },
  });

export const createBuildUrl = (parentUrl: any, configurationState: any) =>
  axios.post(`https://${parentUrl}.ca/api/createBuild/`, {
    configurationState,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "X-Requested-With, Content-Type, X-Token-Auth,Authorization",
    },
  });
