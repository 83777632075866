import { LocaleObject } from 'yup/es/locale';
import { ValidLanguage } from '..';
import en from './locales.en'
import fr from './locales.fr';

const locales: { [K in ValidLanguage]: LocaleObject } = {
    en,
    fr,
};

export default locales;