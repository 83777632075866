import { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

export interface VehicleModelCardProps {
  img: string;
  make: string;
  model: string;
  modelSlug: string;
  year: number;
}

export default function VehicleModelCard({
  img,
  make,
  model,
  modelSlug,
  year,
}: PropsWithChildren<VehicleModelCardProps>) {
  const { parentUrl, make: makeSlug } = useParams();

  return (
    <Card className="h-100">
      <Card.Img className="ratio-4-3" src={img} alt={`${make} ${model}`} />
      <Card.Body className="d-flex flex-column">
        <Link
          to={`/${parentUrl}/${makeSlug}/${modelSlug}`}
          className="text-uppercase btn btn-primary"
        >
          {make} {model}
        </Link>
      </Card.Body>
    </Card>
  );
}
