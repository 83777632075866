import { useEffect, useContext, PropsWithChildren } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";

export interface InteriorAccessoryOptions {
  id?: string | number;
  price?: string | number;
}

export interface InteriorAccessoryOptionsAccordionProps {
  title: string;
  interiorAccessoryOptions: InteriorAccessoryOptions[];
  eventKey: string;
}
export default function InteriorAccessoriesOptionsAccordion({
  title,
  interiorAccessoryOptions,
  eventKey,
}: PropsWithChildren<InteriorAccessoryOptionsAccordionProps>) {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );

  function handleChecked(i: any) {
    let interiorAccessoryArray = [
      ...configurationState.interiorAccessoryOption,
    ];
    let checked = false;
    interiorAccessoryArray.forEach(function (c) {
      if (c[1] === i) {
        checked = true;
      }
    });
    return checked;
  }

  function interiorAccessoryTotal(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      setConfigurationState({
        ...configurationState,
        interiorAccessoryOption: [
          ...configurationState.interiorAccessoryOption,
          [
            event.target.value,
            event.target.attributes[5].value,
            event.target.attributes[6].value,
            event.target.attributes[7].value,
          ],
        ],
        interiorAccessoryOptionTotalPrice: [
          ...configurationState.interiorAccessoryOptionTotalPrice,
          event.target.value,
        ],
      });
    } else {
      const index =
        configurationState.interiorAccessoryOptionTotalPrice.indexOf(
          event.target.value
        );
      configurationState.interiorAccessoryOption.splice(index, 1);
      configurationState.interiorAccessoryOptionTotalPrice.splice(index, 1);
      setConfigurationState({
        ...configurationState,
        interiorAccessoryOption: [
          ...configurationState.interiorAccessoryOption,
        ],
        interiorAccessoryOptionTotalPrice: [
          ...configurationState.interiorAccessoryOptionTotalPrice,
        ],
      });
    }
  }

  const cumulatedInteriorAccessoryPrice = () => {
    let counters = [...configurationState.interiorAccessoryOptionTotalPrice];
    let total = 0;
    counters.forEach(function (c) {
      let e = parseInt(c);
      total += e;
    });

    return total;
  };

  let totalInteriorAccessory = cumulatedInteriorAccessoryPrice();

  useEffect(() => {
    if (totalInteriorAccessory >= 0) {
      setConfigurationState((e: any) => ({
        ...e,
        interiorAccessoryCumulatedPrice: totalInteriorAccessory,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalInteriorAccessory]);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        {interiorAccessoryOptions?.map((accessory: any, key: number) => (
          <Card
            as={`label`}
            htmlFor={`interior-accessory-${key}`}
            key={key}
            className={`optionCard mb-3 ${
              handleChecked(
                `${lang === "fr" ? accessory.title_fr : accessory.title_en}`
              )
                ? "border-primary"
                : ""
            }`}
            role="button"
          >
            <Card.Body>
              <div className="d-flex">
                <input
                  id={`interior-accessory-${key}`}
                  type="checkbox"
                  className="form-check-input me-3 flex-shrink-0"
                  readOnly // readOnly nessessary to handle the checked without onChange
                  name="interior-accessory"
                  data-value={`${
                    lang === "fr" ? accessory.title_fr : accessory.title_en
                  }`}
                  data-img={accessory.img_url}
                  data-desc={`${
                    lang === "fr"
                      ? accessory.description_fr
                      : accessory.description_en
                  }`}
                  value={accessory.price}
                  onChange={interiorAccessoryTotal}
                  checked={handleChecked(
                    `${lang === "fr" ? accessory.title_fr : accessory.title_en}`
                  )}
                />
                <div className="flex-grow-1">
                  <span>{`${
                    lang === "fr" ? accessory.title_fr : accessory.title_en
                  }`}</span>
                  <div className="fs-4 text-primary text-nowrap">
                    {accessory.price}$
                  </div>
                </div>
                <img
                  className="ratio-4-3 fit-cover img-fluid"
                  src={accessory.img_url}
                  alt={`${
                    lang === "fr" ? accessory.title_fr : accessory.title_en
                  }`}
                  height={60}
                  width={80}
                />
              </div>
            </Card.Body>
          </Card>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
