import { useEffect, useState, useContext, PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ListGroup,
  ListGroupItem,
  Tabs,
  Tab,
  Collapse,
  Form,
} from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import PriceFormatter from "../../helpers/PriceFormater";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";
import {
  vehiclePrice,
  totalAccessoryPrice,
  taxeTps,
  taxeTvq,
  vehicleFinalTotalPrice,
  paymentMonthlyLoan,
  paymentByWeeklyLoan,
  paymentMonthlyLease,
  paymentByWeeklyLease,
  getEstimatedLeaseMonthlyPayment,
  getEstimatedLoanMonthlyPayment,
} from "../../helpers/PaymentCalculatorHelper";
import { getVehicleLender } from "../../actions";
import InvoiceDiscount from "../common/InvoiceDiscount";

export interface InvoiceModalProps {
  open: boolean;
  toggleOpen: any;
}

export default function InvoiceModal({
  open,
  toggleOpen,
}: PropsWithChildren<InvoiceModalProps>) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { parentUrl, make, model, trim } = useParams();
  const lang = i18n.language;
  const [showPriceVehicle, setShowPriceVehicle] = useState(false);
  const [showPriceOption, setShowPriceOption] = useState(false);
  const [showCreditOption, setShowCreditOption] = useState(false);
  const [showTaxe, setShowTaxe] = useState(false);
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );
  const [vehicleLender] = useSelector((state: any) => state.vehicleLender);
  const [summaryPage, setSummaryPage] = useState(false);

  function getPaymentMethod(event: any) {
    let method = event.target.dataset.rrUiEventKey;
    setConfigurationState({
      ...configurationState,
      paymentMethod: method,
      incentiveOption: [],
      cashIncentiveOption: [],
      cumulatedCashIncentive: 0,
      cumulatedIncentiveTotal: 0,
    });
  }

  function selectedLoanDuration(event: any) {
    let loanDuration = parseInt(event.target.value);
    let loanRates = parseFloat(
      event.target.selectedOptions[0].attributes[1].value
    );
    setConfigurationState({
      ...configurationState,
      loanDuration: loanDuration,
      loanRates: loanRates,
    });
  }

  const handleChange = (e: any) => {
    setConfigurationState((prevForm: any) => ({
      ...prevForm,
      [e.target.name]:
        e.target.type !== "checkbox" ? e.target.value : e.target.checked,
    }));
  };

  let tps = taxeTps(configurationState);
  let tvq = taxeTvq(configurationState);

  useEffect(() => {
    dispatch<any>(getVehicleLender(parentUrl, model, trim, lang));
  }, [dispatch, parentUrl, lang, model, trim]);

  const termsLoan =
    vehicleLender?.loan?.programs?.programValues?.valueVariations[0]
      ?.programValues[0]?.terms;
  const termsLease = vehicleLender?.lease?.guaranteed;
  const leaseAnnualKilo = vehicleLender?.lease?.guaranteed?.[0]?.residual;

  function getTotalIncentive(configurationState: any) {
    let total =
      configurationState.cashEvent + configurationState.cumulatedIncentiveTotal;
    return total;
  }

  let residualFirst = termsLease?.filter((e: any) => {
    return e?.to === configurationState.leaseDuration;
  });
  let residual = residualFirst?.[0]?.residual?.filter((e: any) => {
    return e.mileage === configurationState.annualKilometer;
  });

  const initialLoanRates =
    vehicleLender?.loan?.programs?.programValues?.valueVariations[0]?.programValues[0]?.terms?.filter(
      (e: any) => e.to === configurationState.loanDuration
    );

  const initialLeaseRate = vehicleLender?.lease?.guaranteed?.filter(
    (e: any) => e.to === configurationState.leaseDuration
  );

  function selectedLeaseDuration(event: any) {
    let leaseDuration = parseInt(event.target.value);
    let leaseRates = parseFloat(
      event.target.selectedOptions[0].attributes[1].value
    );
    setConfigurationState({
      ...configurationState,
      leaseDuration: leaseDuration,
      leaseRates: leaseRates,
      residual: residual?.[0]?.residual,
    });
  }

  function selectedAnnualKilometer(event: any) {
    let annualKilometer = parseInt(event.target.value);
    setConfigurationState({
      ...configurationState,
      residual: residual?.[0]?.residual,
      annualKilometer: annualKilometer,
    });
  }

  useEffect(() => {
    setConfigurationState({
      ...configurationState,
      loanRates: initialLoanRates?.[0]?.value,
      leaseRates: initialLeaseRate?.[0]?.value * 2400,
      residual: residual?.[0]?.residual,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleLender]);

  useEffect(() => {
    setSummaryPage(!window.location.pathname.endsWith('summary') && !window.location.pathname.endsWith('sommaire'))
  }, [window.location.pathname]);

  return (
    <>
      <Modal show={open} fullscreen="md-down" onHide={toggleOpen}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className="py-2 bg-light text-center">
            <Row xs={2}>
              <Col>{t("invoice.totalConfiguration")}</Col>
              <Col>{t("invoice.estimatedFinancing")}</Col>
            </Row>
          </Container>
          <Container>
            <Row xs={2} className="align-items-center text-center">
              <Col>
                <span className="fs-4">
                  <PriceFormatter
                    price={
                      configurationState.msrp +
                      configurationState.prepFee +
                      configurationState.acFee +
                      configurationState.tiresFee
                    }
                  />
                </span>
              </Col>
              <Col>
                <span className="fs-4">
                  {configurationState.paymentMethod === "lease" ? (
                    <PriceFormatter
                      price={getEstimatedLeaseMonthlyPayment(
                        configurationState
                      )}
                    />
                  ) : (
                    <PriceFormatter
                      price={getEstimatedLoanMonthlyPayment(configurationState)}
                    />
                  )}
                  <>{t("invoice.month")}</>
                </span>
              </Col>
            </Row>
          </Container>
          <Tabs
            defaultActiveKey={configurationState.paymentMethod}
            id="uncontrolled-tab-example"
            className="mb-3"
            onClick={(e) => getPaymentMethod(e)}
          >
            <Tab eventKey="loan" title="Loan">
              <Collapse in={true}>
                <div>
                  <div className="pt-3">
                    <Row>
                      <Col xs="6" className="">
                        <Form.Select
                          onChange={(e) => selectedLoanDuration(e)}
                          value={configurationState.loanDuration}
                        >
                          {termsLoan?.map((term: any, i: any) => (
                            <option
                              key={i}
                              value={term.month}
                              data-value={term.value}
                            >
                              {term.month} Mois à {term.value} %
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-center pt-3">
                    <div className="px-3 py-2 bg-light">
                      <Row xs="4">
                        <Col>Duration</Col>
                        <Col>Rate</Col>
                        <Col>Monthly</Col>
                        <Col>Bi-weekly</Col>
                      </Row>
                    </div>
                    <div className="px-3 py-2">
                      {termsLoan?.map((term: any, i: any) => (
                        <Row key={i} xs="4">
                          <Col>{term.month}</Col>
                          <Col>{term.value} %</Col>
                          <Col>
                            <PriceFormatter
                              price={paymentMonthlyLoan(
                                configurationState,
                                term.month,
                                term.value
                              )}
                            />
                          </Col>
                          <Col>
                            <PriceFormatter
                              price={paymentByWeeklyLoan(
                                configurationState,
                                term.month,
                                term.value
                              )}
                            />
                          </Col>
                        </Row>
                      ))}
                    </div>
                    {vehicleLender?.loan?.rebates && (
                      <div className="mb-3">
                        {vehicleLender?.loan?.rebates.map(
                          (rebate: any, i: any) => (
                            <InvoiceDiscount key={i} rebate={rebate} />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </Tab>
            <Tab eventKey="lease" title="Lease">
              <Collapse in={true}>
                <div>
                  <div className="pt-3">
                    <Row>
                      <Col xs="6" className="">
                        <Form.Select
                          onChange={(e) => selectedLeaseDuration(e)}
                          value={configurationState.leaseDuration}
                        >
                          {termsLease?.map((term: any) => (
                            <option
                              key={term.month}
                              value={term.month}
                              data-value={term.value * 2400}
                            >
                              {term.month} Mois à {term.value * 2400} %
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          onChange={(e) => selectedAnnualKilometer(e)}
                          value={configurationState.annualKilometer}
                        >
                          {leaseAnnualKilo?.map((mileage: any) => (
                            <option
                              key={mileage.mileage}
                              value={mileage.mileage}
                            >
                              {mileage.mileage} KM
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-center pt-3">
                    <div className="px-3 py-2 bg-light">
                      <Row xs="4">
                        <Col>Duration</Col>
                        <Col>Rates</Col>
                        <Col>Monthly</Col>
                        <Col>Bi-weekly</Col>
                      </Row>
                    </div>
                    <div className="px-3 py-2">
                      {termsLease?.map((term: any, i: any) => (
                        <Row key={i} xs="4">
                          <Col>{term.month}</Col>
                          <Col>{term.value * 2400} %</Col>
                          <Col>
                            <PriceFormatter
                              price={paymentMonthlyLease(
                                configurationState,
                                term.month,
                                term.value * 2400
                              )}
                            />
                          </Col>
                          <Col>
                            <PriceFormatter
                              price={paymentByWeeklyLease(
                                configurationState,
                                term.month,
                                term.value * 2400
                              )}
                            />
                          </Col>
                        </Row>
                      ))}
                    </div>
                    {vehicleLender?.lease?.rebates && (
                      <div className="mb-3">
                        {vehicleLender?.lease?.rebates.map(
                          (rebate: any, i: any) => (
                            <InvoiceDiscount key={i} rebate={rebate} />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </Tab>
            <Tab eventKey="cash" title="Cash">
              <Collapse in={true}>
                <div>
                  {vehicleLender?.cash?.rebates && (
                    <div className="mb-3">
                      {vehicleLender?.cash?.rebates.map(
                        (rebate: any, i: any) => (
                          <InvoiceDiscount key={i} rebate={rebate} />
                        )
                      )}
                    </div>
                  )}
                </div>
              </Collapse>
            </Tab>
          </Tabs>

          <div>
            <Form.Group className="mb-2" controlId="tradein">
              <Form.Label>Trade-In</Form.Label>
              <Form.Control
                name="tradeIn"
                type="number"
                onChange={(e) => handleChange(e)}
                value={configurationState?.tradeIn}
              />
            </Form.Group>
            {configurationState.paymentMethod !== "cash" ? (
              <Form.Group className="mb-2" controlId="downpayment">
                <Form.Label>Down Payment</Form.Label>
                <Form.Control
                  name="downPayment"
                  type=" number"
                  onChange={(e) => handleChange(e)}
                  value={configurationState?.downPayment}
                />
              </Form.Group>
            ) : (
              ""
            )}
          </div>

          <div className="pb-2 mb-3 border-bottom fw-bold fs-3">
            {/* {configurationState.paymentMethod} */}
          </div>
          <ListGroup variant="flush">
            <ListGroupItem>
              <div className="d-flex justify-content-between fs-5">
                <div onClick={() => setShowPriceVehicle(!showPriceVehicle)}>
                  <span className="me-2">Prix du véhicule</span>
                  <BsChevronDown
                    className={`small invoiceRow__chevron ${
                      showPriceVehicle ? "invoiceRow__chevron--flipped" : ""
                    }`}
                  />
                </div>
                <div className="fw-bold">
                  <PriceFormatter price={vehiclePrice(configurationState)} />
                </div>
              </div>
              <Collapse in={showPriceVehicle}>
                <div>
                  <div className="pt-1">
                    <div className="d-flex justify-content-between">
                      <div className="ps-2">PDSF</div>
                      <div>
                        <PriceFormatter price={configurationState.msrp} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="ps-2">
                        Frais de transport et de préparation
                      </div>
                      <div>
                        <PriceFormatter price={configurationState.prepFee} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="ps-2">Taxe sur le climatiseur</div>
                      <div>
                        <PriceFormatter price={configurationState.acFee} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="ps-2">Droits sur les pneus neufs</div>
                      <div>
                        <PriceFormatter price={configurationState.tiresFee} />
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex justify-content-between fs-5">
                <div onClick={() => setShowPriceOption(!showPriceOption)}>
                  <span className="me-2">Prix des options</span>
                  <BsChevronDown
                    className={`small invoiceRow__chevron ${
                      showPriceOption ? "invoiceRow__chevron--flipped" : ""
                    }`}
                  />
                </div>
                <div className="fw-bold">
                  <PriceFormatter
                    price={totalAccessoryPrice(configurationState)}
                  />
                </div>
              </div>
              <Collapse in={showPriceOption}>
                <div>
                  <div className="pt-1">
                    {configurationState.groupAccessoryOption.map(
                      (accessory: any, i: any) => (
                        <div key={i} className="d-flex justify-content-between">
                          <div className="ps-2">{accessory[1]}</div>
                          <div>
                            <PriceFormatter price={accessory[0]} />
                          </div>
                        </div>
                      )
                    )}
                    {configurationState.accessoryOption.map(
                      (accessory: any, i: any) => (
                        <div key={i} className="d-flex justify-content-between">
                          <div className="ps-2">{accessory[1]}</div>
                          <div>
                            <PriceFormatter price={accessory[0]} />
                          </div>
                        </div>
                      )
                    )}
                    {configurationState.interiorAccessoryOption.map(
                      (accessory: any, i: any) => (
                        <div key={i} className="d-flex justify-content-between">
                          <div className="ps-2">{accessory[1]}</div>
                          <div>
                            <PriceFormatter price={accessory[0]} />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Collapse>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex justify-content-between fs-5">
                <div onClick={() => setShowCreditOption(!showCreditOption)}>
                  <span className="me-2">Crédits</span>
                  <BsChevronDown
                    className={`small invoiceRow__chevron ${
                      showCreditOption ? "invoiceRow__chevron--flipped" : ""
                    }`}
                  />
                </div>
                <div className="fw-bold">
                  <PriceFormatter
                    price={getTotalIncentive(configurationState)}
                  />
                </div>
              </div>
              <Collapse in={showCreditOption}>
                <div>
                  <div className="pt-1">
                    {vehicleLender?.cash?.cashEvent ? (
                      <div className="d-flex justify-content-between">
                        <div className="ps-2">Incitatif en vigueur</div>
                        <div>
                          <PriceFormatter
                            price={
                              vehicleLender?.cash?.cashEvent.programValues
                                .valueVariations[0].programValues[0].cash
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {configurationState.incentiveOption.map(
                      (incentive: any, i: any) => (
                        <div key={i} className="d-flex justify-content-between">
                          <div className="ps-2">{incentive[1]}</div>
                          <div>
                            <PriceFormatter price={incentive[0]} />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Collapse>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex justify-content-between fs-5">
                <div onClick={() => setShowTaxe(!showTaxe)}>
                  <span className="me-2">Taxes</span>
                  <BsChevronDown
                    className={`small invoiceRow__chevron ${
                      showTaxe ? "invoiceRow__chevron--flipped" : ""
                    }`}
                  />
                </div>
                <div className="fw-bold">
                  <PriceFormatter
                    price={
                      taxeTvq(configurationState) + taxeTps(configurationState)
                    }
                  />
                </div>
              </div>
              <Collapse in={showTaxe}>
                <div>
                  <div className="pt-1">
                    <div className="d-flex justify-content-between">
                      <div className="ps-2">TVQ</div>
                      <div>
                        <PriceFormatter price={taxeTvq(configurationState)} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="ps-2">TPS</div>
                      <div>
                        <PriceFormatter price={taxeTps(configurationState)} />
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex justify-content-between fs-5">
                <div>
                  <span className="me-2">Total</span>
                </div>
                <div className="fw-bold">
                  <PriceFormatter
                    price={vehicleFinalTotalPrice(configurationState, tps, tvq)}
                  />
                </div>
              </div>
            </ListGroupItem>
          </ListGroup>
          {configurationState.paymentMethod !== "cash" && (
            <div className="bg-light py-2 px-3 mb-3">
              <div className="d-flex justify-content-between">
                <div>{t("invoice.estimatedFinancing")}</div>
                <div className="fw-bold">
                  {configurationState.paymentMethod === "lease" ? (
                    <PriceFormatter
                      price={getEstimatedLeaseMonthlyPayment(
                        configurationState
                      )}
                    />
                  ) : (
                    <PriceFormatter
                      price={getEstimatedLoanMonthlyPayment(configurationState)}
                    />
                  )}
                  <>{t("invoice.month")}</>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        {summaryPage ? 
            <Modal.Footer className="flex-nowrap p-0 p-md-2">
            {/* <Link to="/protections" className="btn btn-secondary rounded-0 py-3 w-100 m-0 m-md-2">{t("invoice.modal.protection_btn")}</Link> */}
            <Link
                onClick={toggleOpen}
                to={`/${parentUrl}/${make}/${model}/${trim}/summary`}
                className="btn btn-primary rounded-0 py-3 w-100 m-0 m-md-2"
            >
                {t("invoice.modalNextBtn")}
            </Link>
            </Modal.Footer>
        : null}
      </Modal>
    </>
  );
}
