import { useEffect, useState, useContext, PropsWithChildren } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { ConfigurationStateContext } from "../../ConfigurationStateProvider";

export interface WheelOptions {
  id: string | number;
  price?: string | number;
  wheelImg?: string;
}

export interface WheelOptionsAccordionProps {
  title: string;
  wheelOptions: WheelOptions[];
  eventKey: string;
}

export default function WheelOptionsAccordion({
  title,
  wheelOptions,
  eventKey,
}: PropsWithChildren<WheelOptionsAccordionProps>) {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [wheelsOptions, setWheelsOptions] = useState<any>([]);
  const [configurationState, setConfigurationState] = useContext(
    ConfigurationStateContext
  );
  useEffect(() => {
    // set custom id to each wheel
    setWheelsOptions(
      wheelOptions?.map((el, i) => ({
        ...el,
        id: i + 1, //the id=0 is the default wheel so we start ids at 1
      }))
    );
  }, [wheelOptions]);

  // wheel is null if we select default wheel
  function selectOption(wheel: any = null) {
    if (!wheel) {
      setConfigurationState({
        ...configurationState,
        wheelOption: [], // null if wheel is default
        wheelOptionPrice: null, // null if wheel is default
      });
    } else {
      const wheelTitle = lang === "fr" ? wheel.title_fr : wheel.title_en;

      setConfigurationState({
        ...configurationState,
        wheelOption: [wheel.id, wheelTitle, wheel.img_url],
        wheelOptionPrice: parseFloat(wheel.price),
      });
    }
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        <Card
          className={`optionCard mb-3 ${
            !configurationState.wheelOption.length ? "border-primary" : ""
          }`}
          onClick={() => selectOption(null)}
          role="button"
        >
          <Card.Body>
            <div className="d-flex">
              <input
                id="wheel-0"
                className="form-check-input me-3 flex-shrink-0"
                type="checkbox"
                name="wheel"
                value="0"
                readOnly // readOnly nessessary to handle the checked without onChange
                // onChange={} // we dont want an onChange because we change the input value onClick of the entire card
                checked={!configurationState.wheelOption.length} // if wheelOption is empty, we set default wheel
              />
              <div className="flex-grow-1">
                <label htmlFor={`wheel-0`}>Roue d'origine</label>
              </div>
            </div>
          </Card.Body>
        </Card>
        {wheelsOptions?.map((wheel: any) => (
          <Card
            key={wheel.id}
            className={`optionCard mb-3 ${
              wheel.id === configurationState.wheelOption[0]
                ? "border-primary"
                : ""
            }`}
            onClick={() => selectOption(wheel)}
            role="button"
          >
            <Card.Body>
              <div className="d-flex">
                <input
                  className="form-check-input me-3 flex-shrink-0"
                  type="checkbox"
                  id={`wheel-${wheel.id}`}
                  readOnly // readOnly nessessary to handle the checked without onChange
                  name="wheel"
                  value={wheel.id}
                  checked={wheel.id === configurationState.wheelOption[0]}
                />
                <div className="flex-grow-1">
                  <label htmlFor={`wheel-${wheel.id}`}>{`${
                    lang === "fr" ? wheel.title_fr : wheel.title_en
                  }`}</label>
                  <div className="fs-4 text-primary text-nowrap">
                    {wheel.price}$
                  </div>
                </div>
                <img
                  className="ratio-4-3 img-fluid"
                  src={wheel.img_url}
                  height={60}
                  alt=""
                  width={80}
                />
              </div>
            </Card.Body>
          </Card>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
