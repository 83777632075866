import printValue from 'yup/es/util/printValue';
import {
    MixedLocale,
    StringLocale,
    NumberLocale,
    BooleanLocale,
    DateLocale,
    ObjectLocale,
    ArrayLocale,
    LocaleObject,
} from 'yup/es/locale';

/* eslint-disable no-template-curly-in-string, prefer-template */
const mixed: Required<MixedLocale> = {
    default: '${path} is invalid',
    defined: '${path} must be defined',
    required: '${path} is a required field',
    oneOf: '${path} must be one of the following values: ${values}',
    notOneOf: '${path} must not be one of the following values: ${values}',
    notType: ({ path, type, value, originalValue }) => {
        const castMsg =
            originalValue != null && originalValue !== value
                ? ` (cast from the value \`${printValue(
                      originalValue,
                      true
                  )}\`).`
                : '.';

        return type !== 'mixed'
            ? `${path} must be a \`${type}\` type, ` +
                  `but the final value was: \`${printValue(value, true)}\`` +
                  castMsg
            : `${path} must match the configured type. ` +
                  `The validated value was: \`${printValue(value, true)}\`` +
                  castMsg;
    },
};

const string: Required<StringLocale> = {
    length: '${path} must be exactly ${length} characters',
    min: '${path} must be at least ${min} characters',
    max: '${path} must be at most ${max} characters',
    matches: '${path} must match the following: "${regex}"',
    email: '${path} must be a valid email',
    url: '${path} must be a valid URL',
    uuid: '${path} must be a valid UUID',
    trim: '${path} must be a trimmed string',
    lowercase: '${path} must be a lowercase string',
    uppercase: '${path} must be a upper case string',
};

export const number: Required<NumberLocale> = {
    min: '${path} must be greater than or equal to ${min}',
    max: '${path} must be less than or equal to ${max}',
    lessThan: '${path} must be less than ${less}',
    moreThan: '${path} must be greater than ${more}',
    positive: '${path} must be a positive number',
    negative: '${path} must be a negative number',
    integer: '${path} must be an integer',
};

const date: Required<DateLocale> = {
    min: '${path} must be later than ${min}',
    max: '${path} must be at earlier than ${max}',
};

const boolean: BooleanLocale = {
    isValue: '${path} must be ${value}',
};

const object: Required<ObjectLocale> = {
    noUnknown: '${path} has unspecified keys: ${unknown}',
};

const array: Required<ArrayLocale> = {
    min: '${path} must have at least ${min} items',
    max: '${path} must have less than or equal to ${max} items',
    length: '${path} must have ${length} items',
};

const locale: LocaleObject = {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
};

export default locale;